<template>
  <div class="hints-landing-page">
    <div class="limited_content">
      <div class="content">
        <div>
          <div class="heading-2">Save time and make discoveries with RecordFinder™</div>
          <div class="text-md">
            Do genealogy the easy way by filling out your family tree, and letting RecordFinder™ do the work!
          </div>
          <div class="text-md">
            Get record matches from our genealogy database, and be notified of new collections matching your tree.
          </div>
          <div class="button-container">
            <subscription-plans-link :from-hints="true" label="Start Free Trial" class="fat"
              >Start Free Trial</subscription-plans-link
            >
          </div>
        </div>
        <img :src="$getAsset('/assets/video/my-china-roots_hints-review.gif')" width="536" height="333" />
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionPlansLink from '@/components/common/buttons/SubscriptionPlansLink';

export default {
  name: 'HintsLandingPage',
  components: {SubscriptionPlansLink},
};
</script>

<style lang="scss" scoped>
.hints-landing-page {
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    .heading-2 {
      margin-bottom: 16px;
    }

    .button-container {
      display: flex;
      margin: 32px 0 56px;
    }

    img {
      flex-grow: 0;
      flex-shrink: 0;
    }
    .text-md {
      color: $neutral-600;
    }
    .text-md + .text-md {
      margin-top: 28px;
    }
  }

  @media only screen and (min-width: $main-menu-breakpoint) {
    .content {
      flex-direction: row;
      column-gap: 80px;
      margin: 54px 0 70px;

      .heading-2 {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 20px;
      }

      .text-md {
        font-size: 20px;
        line-height: 28px;
      }

      .button-container {
        margin: 40px 0 0;
      }
    }
  }
}
</style>
